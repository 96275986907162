import _ReactPlayer from "./lib/ReactPlayer";
import _YouTube from "./lib/players/YouTube";
var exports = {};
var createReactPlayer = _ReactPlayer.createReactPlayer;
var Player = _YouTube.default;
exports = createReactPlayer([{
  key: "youtube",
  canPlay: Player.canPlay,
  lazyPlayer: Player
}]);
export default exports;